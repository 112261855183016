html {
  /* background: url('../paris.jpg') no-repeat center center fixed;  */
  /* background-color: lightgray; */
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Roboto', sans-serif;
}

.login-dialog {
  margin-top: 4rem;
}

.loose-form {
  margin-bottom: 1rem;
}

.confirm-btn {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.headervalues {
  padding-top: 2rem;
}

.loose {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.front {
  margin-top: 0.2rem;
  background-color: #fff;
  border-radius: 0.25rem;
  min-height: 30vh;
  margin-bottom: 50px;
  padding-bottom: 20px;
}

.tesoma-page-title {
  margin-top: 1rem;
}

.loading-spinner {
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
}

.myform {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1.1rem;
  outline: 0;
  max-width: 500px;
}
.tx-tfm {
  text-transform: uppercase;
}
.mybtn {
  border-radius: 50px;
}

.login-or {
  position: relative;
  color: #aaa;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.span-or {
  display: block;
  position: absolute;
  left: 50%;
  top: -2px;
  margin-left: -25px;
  background-color: #fff;
  width: 50px;
  text-align: center;
}
.hr-or {
  height: 1px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.google {
  color: #666;
  width: 100%;
  height: 40px;
  text-align: center;
  outline: none;
  border: 1px solid lightgrey;
}
form .error {
  color: #ff0000;
}

.full-page-background {
  background-color: lightgray;
}
/* .full-page-background {
  position: absolute;
  z-index: 1;
  height: '110vh';
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center; } */

.wrapper-full-page {
  min-height: 100vh;
  height: auto;
}

.menu-flags {
  border-width: 0px !important;
  border-style: none;
  margin-right: 10px;
}

.brandlogo {
  height: 45px;
  vertical-align: middle;
}

.brandtext {
  font-family: 'Poppins', sans-serif;
  margin-left: 5px;
  font-weight: 400;
}

.appname {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}
